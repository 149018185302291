import React, { useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import sopInfo from "../assets/sopInfo.png";
import Footer from "./Footer";
import NavLinks from "./NavLinks";
import UiWarning from "./UiWarning";
import { usePrice } from "../hooks/useContext";
import beanbean from "../assets/beanbean.svg";

export default function SopInfo() {
  const navigate = useNavigate();
  const {
    rcmPrice,
    sopPrice,
    prpPrice,
    promotionLable,
    lableContent,
    promotionAlldocs,
  } = usePrice();

  const asistSec = useRef();

  const [className, setClassName] = useState("sticky");

  const handleRcm = () => {
    navigate("/myWritings", {
      state: {
        from: "rcm",
      },
    });
  };
  const handleSop = () => {
    navigate("/myWritings", {
      state: {
        from: "sop",
      },
    });
  };

  const handlePrp = () => {
    navigate("/myWritings", {
      state: {
        from: "prp",
      },
    });
  };

  const handleAsist = () => {
    navigate("/asistant");
  };

  const handleScroll = (className) => {
    if (className !== "afterStickySop" && window.pageYOffset >= 2013) {
      setClassName("afterStickySop");
    } else if (className === "afterStickySop" && window.pageYOffset < 2013) {
      setClassName("sticky");
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    window.onscroll = () => handleScroll(className);
  }, [className]);

  return (
    <div style={{ textAlign: "left" }}>
      <img
        alt="PS-Personal Statement"
        src={sopInfo}
        style={{ margin: "-30px 0 -60px 0", width: "100vw" }}
      />
      <p
        style={{
          position: "relative",
          top: "-100px",
          left: "80px",
          color: "white",
        }}
      >
        <h2>Statement of Purpose</h2>
        <h1>个人陈述撰写</h1>
        <h1 className="offscreen">留学文书怎么写</h1>
      </p>
      <div className="infoP  threeInfo">
        <div style={{ margin: "0", marginLeft: "-20px" }}>
          <UiWarning />
        </div>
        <NavLinks />

        <div className="dynamic" style={{ margin: "0" }}>
          <div
            className={className}
            style={{ margin: "0", paddingBottom: "40px" }}
          >
            <h4 style={{ marginBottom: "16px" }}>
              个人陈述 Statement of Purpose
            </h4>
            <div style={{ margin: "30px 0" }}>
              <p>
                适用于无初稿的申请人。
                <br />
                我们的智能AI将根据您填写的问卷量身定制个性化文书,包含英文初稿、整体反馈、
                1 次免费润色服务、以及文书终稿。
              </p>
              <p>约800-1000单词</p>
            </div>
            <div
              className={!promotionLable ? "offscreen" : undefined}
              style={{
                backgroundColor: "#DB5461",
                color: "white",
                padding: "0 5px",
                width: "fit-content",
                marginBottom: "20px",
              }}
            >
              {lableContent}
            </div>
            <p className="colorText" style={{ margin: "40px 0 30px 0" }}>
              <span className={promotionLable ? "deletePrice" : "biggerText"}>
                ¥{sopPrice}&nbsp;
              </span>
              <span className="redText">&nbsp;¥</span>
              <span className="biggerText">{sopPrice * promotionAlldocs}</span>
            </p>
            <button onClick={handleSop}>选择服务</button>
          </div>

          <div
            className="detailInfo"
            style={{ marginLeft: "auto", marginRight: "0" }}
          >
            <div style={{ margin: "0" }}>
              <h2 className="colorText infoTopic">目的</h2>
              <p>
                个人陈述在留学申请中扮演了关键的角色，它是一个
                <span style={{ fontFamily: "PingFangSC-Semibold, sans-serif" }}>
                  独特的故事
                </span>
                ，串起您生命历程中的各个选择，令评委了解您深层的
                <span style={{ fontFamily: "PingFangSC-Semibold, sans-serif" }}>
                  内在动机
                </span>
                ，让您在众多申请者中脱颖而出。
              </p>
              <p>
                个人陈述强调的并非只是优秀，更多是
                <span style={{ fontFamily: "PingFangSC-Semibold, sans-serif" }}>
                  适合
                </span>
                ———为什么对这所学校感兴趣？为什么对这个项目充满热情？为什么您是和这个项目最匹配的学生？
              </p>
              <p>
                同时，个人陈述中也要说明您
                <span style={{ fontFamily: "PingFangSC-Semibold, sans-serif" }}>
                  毕业后的职业规划
                </span>
                ，不论是朝学术之路或是进入企业界，这个项目如何与您毕业后的方向挂钩。
              </p>
              <p>
                深挖个人经历，重组思路，清晰有逻辑的表达。不要将其视为一个挑战，应该将其视为良机，思考自己和项目是否真正适合。
              </p>
            </div>
            <div style={{ margin: "0" }}>
              <h2 className="colorText infoTopic">架构</h2>
              <p>要写出具备灵魂的个人陈述，您需要准备以下内容：</p>
              <h5>01 个人信息</h5>
              <p></p>
              <h5>02 学习背景</h5>{" "}
              <p>描述您为何对该学习领域充满热忱、修过的相关课程、课堂作业。</p>
              <h5>03 课外活动/ 实习/ 工作经验</h5>{" "}
              <p>
                这一段是展现自己强烈动机和领域知识的好机会。列出在学校课程之外，还做了什么和想申请领域有关的事。
              </p>
              <h5>04 想申请的学校和专业</h5>{" "}
              <p>展示您对想申请的学校和专业有充足了解。</p>
              <h5>05 毕业后的规划</h5>{" "}
              <p>
                您毕业后的规划和专业能够提供的资源和知识是否一致？可以参考校友动向获得更多想法。
              </p>
            </div>
            <div style={{ margin: "-40px 0 0 0" }}>
              <h2 className="colorText infoTopic" style={{ top: "103px" }}>
                服务
                <br />
                流程
              </h2>
              <h5>Step 01</h5>
              <p>点击“选择服务”按钮，进入个人陈述生成流程。</p>
              <h5>Step 02</h5>
              <p>根据提示填写您的个人信息、学术成就、技能和经历等。</p>
              <h5>Step 03</h5>
              <p>
                提交信息并付款后,我们的AI系统将即时为您生成个人陈述英文初稿和整体反馈。
              </p>
              <h5>Step 04</h5>
              <p>
                使用我们赠送的 1 次免费润色服务。根据 AI
                反馈，在初稿上直接加入中文修改内容。
              </p>
              <h5>Step 05</h5>
              <p>
                送出润色修改&#65292;AI系统将即时为您生成个人陈述英文终稿。英文稿件完成后&#65292;您可以随时登录查看所有文书。
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="details rows"
        ref={asistSec}
        style={{
          backgroundColor: "#A9D7F6",
          margin: "100px 0 ",
          width: "100vw",
        }}
      >
        <div style={{ padding: "40px 20px" }}>
          <div
            style={{
              backgroundColor: "#DB5461",
              color: "white",
              padding: "0 5px",
              width: "fit-content",
            }}
          >
            免费體驗
          </div>
          <h1>小豆帮帮</h1>
          <p
            style={{ fontWeight: "600", fontSize: "20px", lineHeight: "36px" }}
          >
            帮助您更了解申请学校及专业的特色，找出在个人陈述中可以强调的特点。
            <br />
            Step 01
            <br />
            <span style={{ fontWeight: "400" }}>
              点击“选择服务”按钮，进入小豆帮帮流程。
            </span>
            <br />
            Step 02
            <br />
            <span style={{ fontWeight: "400" }}>
              复制您要申请的学校官网英文介绍，并粘贴到我们的介面。
            </span>
            <br />
            Step 03
            <br />
            <span style={{ fontWeight: "400" }}>
              AI 系统将自动帮分析申请学校专业特色，提供个人陈述文书建议。
            </span>
            <br />
          </p>
          <button onClick={handleAsist}>选择服务</button>
        </div>
        <div>
          <img
            alt="出国留学申请"
            className="beanbean"
            style={{ margin: "8vw 10vw" }}
            src={beanbean}
          />
        </div>
      </div>

      <div className="infoP  threeInfo">
        <div style={{ margin: "0" }}>
          <div style={{ margin: "0" }}>
            <h2 style={{ marginBottom: "20px" }}>其他服务</h2>
            <h4 style={{ color: "#475569", marginBottom: "24px" }}>
              你可以选择直接生成推荐信和个人陈述，或者润色已有文书{" "}
            </h4>
          </div>
          <div className="infoDivs" style={{ margin: "0" }}>
            <div className="infoDiv">
              <h4 style={{ marginBottom: "16px" }}>
                推荐信 Recommendation Letter
              </h4>
              <div className="info" style={{ marginBottom: "30px" }}>
                <p>
                  适用于无初稿的申请人
                  。根据用户填写的问卷量身定制个性化文书，包含英文初稿、整体反馈、润色修改，以及文书终稿。{" "}
                  <Link
                    to="/sopInfo"
                    style={{ color: "#6B92F7", textDecoration: "underline" }}
                  >
                    查看详情
                  </Link>
                </p>
                <p>约300-500单词 </p>
              </div>
              <div
                className={!promotionLable ? "offscreen" : undefined}
                style={{
                  backgroundColor: "#DB5461",
                  color: "white",
                  padding: "0 5px",
                  width: "fit-content",
                  marginBottom: "20px",
                }}
              >
                {lableContent}
              </div>
              <div>
                <span
                  className="colorText "
                  style={{ position: "absolute", bottom: "30px" }}
                >
                  <span
                    className={promotionLable ? "deletePrice" : "biggerText"}
                  >
                    ¥{rcmPrice}&nbsp;
                  </span>
                  <span className="redText">&nbsp;¥</span>
                  <span className="biggerText">
                    {rcmPrice * promotionAlldocs}
                  </span>
                </span>
                <button
                  style={{
                    position: "absolute",
                    bottom: "40px",
                    right: "32px",
                  }}
                  onClick={handleRcm}
                >
                  选择服务
                </button>
              </div>
            </div>
            <div className="infoDiv">
              <h4 style={{ marginBottom: "16px" }}>文书润色</h4>
              <div className="info">
                <p>
                  适用于有英文初稿、需要语言/拼写/行文逻辑修改的申请人。对文书进行语句修正、结构调整。
                  <Link
                    to="/prpInfo"
                    style={{ color: "#6B92F7", textDecoration: "underline" }}
                  >
                    查看详情
                  </Link>
                </p>
                <p>推荐信500单词內/个人陈述1000单词內</p>
              </div>
              <div
                className={!promotionLable ? "offscreen" : undefined}
                style={{
                  backgroundColor: "#DB5461",
                  color: "white",
                  padding: "0 5px",
                  width: "fit-content",
                  marginBottom: "20px",
                }}
              >
                {lableContent}
              </div>
              <div>
                <span
                  className="colorText"
                  style={{ position: "absolute", bottom: "30px" }}
                >
                  <span
                    className={promotionLable ? "deletePrice" : "biggerText"}
                  >
                    ¥{prpPrice}&nbsp;
                  </span>
                  <span className="redText">&nbsp;¥</span>
                  <span className="biggerText">
                    {prpPrice * promotionAlldocs}
                  </span>
                </span>
                <button
                  style={{
                    position: "absolute",
                    bottom: "40px",
                    right: "32px",
                  }}
                  onClick={handlePrp}
                >
                  选择服务
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
