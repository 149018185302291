import React, { useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import home from "../assets/home.svg";
import Footer from "./Footer";
import NavLinks from "./NavLinks";
import UiWarning from "./UiWarning";
import { usePrice } from "../hooks/useContext";
import home_png from "../assets/home.png";
import peanutBtn_1 from "../assets/peanutBtn_1.svg";
import peanutBtn_2 from "../assets/peanutBtn_2.svg";
import peanutBtn_3 from "../assets/peanutBtn_3.svg";
import peanutBtn_4 from "../assets/peanutBtn_4.svg";
import gift from "../assets/gift.svg";

export default function Home() {
  const navigate = useNavigate();
  const [promoHintClose, setPromoHintClose] = useState(false);

  const {
    rcmPrice,
    sopPrice,
    prpPrice,
    promotionLable,
    lableContent,
    promotionAlldocs,
  } = usePrice();

  const handleAsist = () => {
    navigate("/asistant");
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ textAlign: "left" }}>
      <div className={promoHintClose ? "offscreen" : "promoHint"}>
        <img alt="gift" src={gift} />
        <span
          style={{
            position: "relative",
            top: "-15px",
            margin: "0 20px",
            color: "#db5461",
          }}
        >
          <span className="promotionLable">{lableContent}</span>
          &nbsp;&nbsp;&nbsp;
          <a
            href="/addFile"
            style={{ textDecorationLine: "underline", color: "#db5461" }}
          >
            {"领取优惠"}
          </a>
          &nbsp;→
        </span>
        <span
          className="material-symbols-rounded hover"
          style={{
            color: "#64748B",
            width: "50px",
            height: "50px",
            float: "right",
          }}
          onClick={() => setPromoHintClose(true)}
        >
          close
        </span>
      </div>

      <div
        style={{
          backgroundImage: `url(${home_png})`,
          backgroundSize: "cover",
          height: "57vw",
          position: "relative",
          margin: "-30px 0 -60px 0",
        }}
      >
        <div
          style={{
            paddingTop: "3.5vw",
            color: "white",
            width: "100vw",
            textAlign: "center",
          }}
        >
          <h1 className="offscreen">留学申请,出国留学文书怎么写</h1>
          <h1>落花生，助你落笔生花</h1>
          <h2 style={{ margin: "10px 0 20px 0" }}>
            省钱、省时、省心
            <br />
            在线输入中文信息，智能辅助写作定制化文书
          </h2>
          <button
            className="secondary_btn"
            onClick={() => navigate("/addFile")}
          >
            🎁 领取优惠 &nbsp;→
          </button>
        </div>
      </div>

      <div className="infoP">
        <div style={{ margin: "140px 0 -50px 0", marginLeft: "-20px" }}>
          <UiWarning />
        </div>
        <NavLinks />
        <div className="rows">
          <div>
            <img
              alt="留学文书"
              src={home}
              style={{
                position: "relative",
                right: "120px",
                top: "220px",
                maxWidth: "100%",
                width: "30vw",
              }}
            />
          </div>
          <div className="rows">
            <div>
              <div className="infoBox">
                <div
                  className={!promotionLable ? "offscreen" : "promotionLable"}
                >
                  {lableContent}
                </div>
                <div style={{ color: "#0F172A", margin: "20px 0" }}>
                  <div
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                    }}
                  >
                    推荐信撰写
                  </div>
                  <div>一次撰写＋一次润色</div>
                </div>
                <div>
                  <span
                    className={
                      promotionLable ? "originalPrice" : "currentPrice"
                    }
                  >
                    ¥{rcmPrice}
                  </span>
                  <span
                    className={promotionLable ? "currentPrice" : "offscreen"}
                  >
                    ¥{rcmPrice * promotionAlldocs}
                  </span>
                  <img
                    alt="RL-Recommendation Letter"
                    src={peanutBtn_1}
                    className="peanutBtn"
                  />
                  <span
                    className="peanutCtn"
                    onClick={() => navigate("/rcmInfo")}
                  >
                    查看详情
                  </span>
                </div>
              </div>
              <div className="infoBox">
                <div
                  className={!promotionLable ? "offscreen" : "promotionLable"}
                >
                  {lableContent}
                </div>
                <div style={{ color: "#0F172A", margin: "20px 0" }}>
                  <div
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                    }}
                  >
                    个人陈述撰写
                  </div>
                  <div>一次撰写＋一次润色</div>
                </div>
                <div>
                  <span
                    className={
                      promotionLable ? "originalPrice" : "currentPrice"
                    }
                  >
                    ¥{sopPrice}
                  </span>
                  <span
                    className={promotionLable ? "currentPrice" : "offscreen"}
                  >
                    ¥{sopPrice * promotionAlldocs}
                  </span>
                  <img
                    alt="PS-Personal Statement"
                    src={peanutBtn_2}
                    className="peanutBtn"
                  />
                  <span
                    className="peanutCtn"
                    onClick={() => navigate("/sopInfo")}
                  >
                    查看详情
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="infoBox">
                <div
                  className={!promotionLable ? "offscreen" : "promotionLable"}
                >
                  {lableContent}
                </div>
                <div style={{ color: "#0F172A", margin: "20px 0" }}>
                  <div
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                    }}
                  >
                    文书润色
                  </div>
                  <div>推荐信/个人陈述润色</div>
                </div>
                <div>
                  <span
                    className={
                      promotionLable ? "originalPrice" : "currentPrice"
                    }
                  >
                    ¥{prpPrice}
                  </span>
                  <span
                    className={promotionLable ? "currentPrice" : "offscreen"}
                  >
                    ¥{prpPrice * promotionAlldocs}
                  </span>
                  <img
                    alt="WS-Writing Sample"
                    src={peanutBtn_3}
                    className="peanutBtn"
                  />
                  <span
                    className="peanutCtn"
                    onClick={() => navigate("/prpInfo")}
                  >
                    查看详情
                  </span>
                </div>
              </div>
              <div className="infoBox">
                <div
                  className={!promotionLable ? "offscreen" : undefined}
                  style={{
                    backgroundColor: "#DB5461",
                    color: "white",
                    padding: "0 5px",
                    width: "fit-content",
                    border: "solid 1px #db5461",
                    fontWeight: "600",
                  }}
                >
                  免费体验
                </div>
                <div style={{ color: "#0F172A", margin: "20px 0" }}>
                  <div
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                    }}
                  >
                    小豆帮帮
                  </div>
                  <div>总结分析学校专业特色</div>
                </div>
                <div>
                  <img
                    alt="出国留学申请"
                    src={peanutBtn_4}
                    className="peanutBtn"
                  />
                  <span
                    className="peanutCtn"
                    onClick={() => navigate("/asistant")}
                  >
                    查看详情
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="details rows" style={{ marginTop: "120px" }}>
          <div style={{ flex: "none", width: "20vw" }}>
            <h2>常见问题与解答</h2>
          </div>
          <div style={{ flex: "auto" }}>
            <div
              style={{
                backgroundColor: "#F8FAFC",
                padding: "40px",
                marginBottom: "24px",
              }}
            >
              <div>
                <div
                  style={{
                    color: "#0F172A",
                    fontStyle: "italic",
                    fontSize: "20px",
                  }}
                >
                  落花生和 ChatGPT 的区别是？
                </div>
                <br />
              </div>
              <div>
                落花生是以留学文书为核心的产品，我们运用过去积攒的留学行业知识优化
                AI。主要三大区别如下:
              </div>
              <br />
              <table
                className="table"
                style={{ textAlign: "center", width: "100%" }}
              >
                <thead>
                  <tr style={{ height: "50px" }}>
                    <th
                      style={{
                        textAlign: "left",
                        width: "20%",
                      }}
                    ></th>
                    <th style={{ textAlign: "center", width: "40%" }}>
                      ChatGPT
                    </th>
                    <th style={{ textAlign: "center", width: "40%" }}>
                      落花生
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={0} style={{ height: "50px" }}>
                    <td>功能</td>
                    <td>一般性功能</td>
                    <td>面向留学文书写作优化</td>
                  </tr>
                  <tr key={2} style={{ height: "50px" }}>
                    <td>流程 </td>
                    <td>无引导</td>
                    <td>引导学生提供留学文书相关信息</td>
                  </tr>
                  <tr key={3} style={{ height: "50px" }}>
                    <td>AI 检测</td>
                    <td>无</td>
                    <td>避免被检测为 AI 内容</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              style={{
                backgroundColor: "#F8FAFC",
                padding: "40px",
                marginBottom: "24px",
              }}
            >
              <div>
                <div
                  style={{
                    color: "#0F172A",
                    fontStyle: "italic",
                    fontSize: "20px",
                  }}
                >
                  由落花生辅助写作的文书，如何应对 AI 检测？
                </div>
                <br />
              </div>
              <div>
                落花生是一款写作辅助产品，着重在引导学生提供和留学文书相关的信息。
                只要提供真实详尽且言之有物的个人信息，基本不会被判定为 AI
                产生；另外，基于开发团队的专业背景，我们在流程中外加了 AI
                检测程序，让您透明的看到每一篇文书的 AI 概率。
                <br />
                如果您的文书被检测到为 AI
                产生的概率过高，加入更多相关的个人信息能够有效降低概率。我们建议每一份文书
                AI 概率都应小于 15%
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
