import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import prpInfo from "../assets/prpInfo.png";
import Footer from "./Footer";
import NavLinks from "./NavLinks";
import UiWarning from "./UiWarning";
import { usePrice } from "../hooks/useContext";

export default function PrpInfo() {
  const navigate = useNavigate();
  const {
    rcmPrice,
    sopPrice,
    prpPrice,
    promotionLable,
    lableContent,
    promotionAlldocs,
  } = usePrice();

  const [className, setClassName] = useState("sticky");

  const handleRcm = () => {
    navigate("/myWritings", {
      state: {
        from: "rcm",
      },
    });
  };
  const handleSop = () => {
    navigate("/myWritings", {
      state: {
        from: "sop",
      },
    });
  };

  const handlePrp = () => {
    navigate("/myWritings", {
      state: {
        from: "prp",
      },
    });
  };

  const handleScroll = (className) => {
    if (className !== "afterStickyPrp" && window.pageYOffset >= 1222) {
      setClassName("afterStickyPrp");
    } else if (className === "afterStickyPrp" && window.pageYOffset < 1222) {
      setClassName("sticky");
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    window.onscroll = () => handleScroll(className);
  }, [className]);

  return (
    <div style={{ textAlign: "left" }}>
      <img
        alt="出国留学申请"
        src={prpInfo}
        style={{ margin: "-30px 0 -60px 0", width: "100vw" }}
      />
      <p
        style={{
          position: "relative",
          top: "-100px",
          left: "80px",
          color: "white",
        }}
      >
        <h1>文书润色</h1>
        <h1 className="offscreen">出国留学文书怎么写</h1>
      </p>
      <div className="infoP  threeInfo">
        <div style={{ margin: "0", marginLeft: "-20px" }}>
          <UiWarning />
        </div>
        <NavLinks />

        <div className="dynamic" style={{ margin: "0" }}>
          <div
            className={className}
            style={{ margin: "0", paddingBottom: "40px" }}
          >
            <h4 style={{ marginBottom: "16px" }}>文书润色</h4>
            <div style={{ margin: "30px 0" }}>
              <p>
                适用于有英文初稿、需要语言/拼写/行文逻辑修改的申请人。对文书进行语句修正、结构调整。
              </p>
              <p>推荐信500单词內; 个人陈述1000单词內。</p>
            </div>
            <div
              className={!promotionLable ? "offscreen" : undefined}
              style={{
                backgroundColor: "#DB5461",
                color: "white",
                padding: "0 5px",
                width: "fit-content",
                marginBottom: "20px",
              }}
            >
              {lableContent}
            </div>
            <p className="colorText" style={{ margin: "40px 0 30px 0" }}>
              <span className={promotionLable ? "deletePrice" : "biggerText"}>
                ¥{prpPrice}&nbsp;
              </span>
              <span className="redText">&nbsp;¥</span>
              <span className="biggerText">{prpPrice * promotionAlldocs}</span>
            </p>
            <button onClick={handlePrp}>选择服务</button>
          </div>

          <div
            className="detailInfo"
            style={{ marginLeft: "auto", marginRight: "0" }}
          >
            <div style={{ margin: "0" }}>
              <h2 className="colorText infoTopic">目的</h2>
              <p>
                替您检查各类大大小小的语言运用问题，包括
                <span style={{ fontFamily: "PingFangSC-Semibold, sans-serif" }}>
                  单词拼写
                </span>
                、
                <span style={{ fontFamily: "PingFangSC-Semibold, sans-serif" }}>
                  标点符号
                </span>
                、
                <span style={{ fontFamily: "PingFangSC-Semibold, sans-serif" }}>
                  句型语法
                </span>
                、
                <span style={{ fontFamily: "PingFangSC-Semibold, sans-serif" }}>
                  行文逻辑
                </span>
                让 AI 系统对您的文书评分，并给出修改建议。{" "}
              </p>
              <p>您也可以直接输入中英夹杂的拼接内容，之后一键润色。 </p>
            </div>
            <div style={{ margin: "-40px 0 0 0" }}>
              <h2 className="colorText infoTopic" style={{ top: "103px" }}>
                服务
                <br />
                流程
              </h2>
              <h5>Step 01</h5>
              <p>点击“选择服务”按钮，进入文书润色流程。</p>
              <h5>Step 02</h5>
              <p>复制您的文书内容并粘贴到落花生介面。</p>
              <h5>Step 03</h5>
              <p>AI 系统将自动帮您的文书评分并显示改进建议。</p>
              <h5>Step 04</h5>
              <p>
                根据AI反馈&#65292;在初稿上直接加入中文修改内容,让您的文书更加个性化。修改完成后&#65292;一键生成润色结果。
              </p>
              <h5>Step 05</h5>
              <p>英文稿件完成后，您可以随时登录查看所有文书。</p>
            </div>
          </div>
        </div>

        <hr
          style={{
            position: "relative",
            left: "-80px",
            width: "100vw",
            height: "1px",
            borderWidth: "0",
            backgroundColor: "#E2E8F0",
            color: "#E2E8F0",
            margin: "80px 0",
          }}
        ></hr>

        <div style={{ margin: "0" }}>
          <div style={{ margin: "0" }}>
            <h2 style={{ marginBottom: "20px" }}>其他服务</h2>
            <h4 style={{ color: "#475569", marginBottom: "24px" }}>
              你可以选择直接生成推荐信和个人陈述，或者润色已有文书{" "}
            </h4>
          </div>
          <div className="infoDivs" style={{ margin: "0" }}>
            <div className="infoDiv">
              <h4 style={{ marginBottom: "16px" }}>
                推荐信 Recommendation Letter
              </h4>
              <div className="info" style={{ marginBottom: "30px" }}>
                <p>
                  适用于无初稿的申请人
                  。根据用户填写的问卷量身定制个性化文书，包含英文初稿、整体反馈、润色修改，以及文书终稿。{" "}
                  <Link
                    to="/sopInfo"
                    style={{ color: "#6B92F7", textDecoration: "underline" }}
                  >
                    查看详情
                  </Link>
                </p>
                <p>约300-500单词</p>
              </div>
              <div
                className={!promotionLable ? "offscreen" : undefined}
                style={{
                  backgroundColor: "#DB5461",
                  color: "white",
                  padding: "0 5px",
                  width: "fit-content",
                  marginBottom: "20px",
                }}
              >
                {lableContent}
              </div>
              <div>
                <span
                  className="colorText"
                  style={{ position: "absolute", bottom: "30px" }}
                >
                  <span
                    className={promotionLable ? "deletePrice" : "biggerText"}
                  >
                    ¥{rcmPrice}&nbsp;
                  </span>
                  <span className="redText">&nbsp;¥</span>
                  <span className="biggerText">
                    {rcmPrice * promotionAlldocs}
                  </span>
                </span>
                <button
                  style={{
                    position: "absolute",
                    bottom: "40px",
                    right: "32px",
                  }}
                  onClick={handleRcm}
                >
                  选择服务
                </button>
              </div>
            </div>
            <div className="infoDiv">
              <h4 style={{ marginBottom: "16px" }}>
                个人陈述 Statement of Purpose
              </h4>
              <div className="info" style={{ marginBottom: "30px" }}>
                <p>
                  适用于无初稿的申请人
                  。根据用户填写的问卷量身定制个性化文书，包含英文初稿、整体反馈、润色修改，以及文书终稿。
                  <Link
                    to="/sopInfo"
                    style={{ color: "#6B92F7", textDecoration: "underline" }}
                  >
                    查看详情
                  </Link>
                </p>
                <p>约800-1000单词</p>
              </div>
              <div
                className={!promotionLable ? "offscreen" : undefined}
                style={{
                  backgroundColor: "#DB5461",
                  color: "white",
                  padding: "0 5px",
                  width: "fit-content",
                  marginBottom: "20px",
                }}
              >
                {lableContent}
              </div>
              <div>
                <span
                  className="colorText"
                  style={{ position: "absolute", bottom: "30px" }}
                >
                  <span
                    className={promotionLable ? "deletePrice" : "biggerText"}
                  >
                    ¥{sopPrice}&nbsp;
                  </span>
                  <span className="redText">&nbsp;¥</span>
                  <span className="biggerText">
                    {sopPrice * promotionAlldocs}
                  </span>
                </span>
                <button
                  style={{
                    position: "absolute",
                    bottom: "40px",
                    right: "32px",
                  }}
                  onClick={handleSop}
                >
                  选择服务
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
